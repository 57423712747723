import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [topic, setTopic] = useState('');
  const [worksheetType, setWorksheetType] = useState('Multiple Choice');
  const [difficulty, setDifficulty] = useState('Easy');
  const [numQuestions, setNumQuestions] = useState(5);
  const [generatedWorksheet, setGeneratedWorksheet] = useState('');
  const [loading, setLoading] = useState(false);

  const generateWorksheet = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://digitalworksheet.com/api/generate', {
        topic,
        worksheetType,
        difficulty,
        numQuestions,
      });
      setGeneratedWorksheet(response.data.worksheet || 'Error generating worksheet.');
    } catch (error) {
      console.error('Error generating worksheet:', error);
      setGeneratedWorksheet('Error generating worksheet.');
    }
    setLoading(false);
  };

  return (
    <div className="app bg-gray-100 min-h-screen p-6">
      <h1 className="text-3xl font-bold mb-4">AI Worksheet Generator</h1>

      <div className="mb-4">
        <label className="block font-medium">Topic:</label>
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="block font-medium">Worksheet Type:</label>
        <select
          className="w-full p-2 border border-gray-300 rounded"
          value={worksheetType}
          onChange={(e) => setWorksheetType(e.target.value)}
        >
          <option value="Multiple Choice">Multiple Choice</option>
          <option value="Fill in the Blanks">Fill in the Blanks</option>
          <option value="Short Answer">Short Answer</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block font-medium">Difficulty Level:</label>
        <select
          className="w-full p-2 border border-gray-300 rounded"
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
        >
          <option value="Easy">Easy</option>
          <option value="Medium">Medium</option>
          <option value="Hard">Hard</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block font-medium">Number of Questions:</label>
        <input
          type="number"
          className="w-full p-2 border border-gray-300 rounded"
          value={numQuestions}
          onChange={(e) => setNumQuestions(e.target.value)}
          min="1"
        />
      </div>

      <button
        className="w-full bg-blue-500 text-white py-2 rounded font-bold"
        onClick={generateWorksheet}
        disabled={loading}
      >
        {loading ? 'Generating...' : 'Generate Worksheet'}
      </button>

      <div className="mt-6">
        {generatedWorksheet && (
          <textarea
            readOnly
            className="w-full p-4 border border-gray-300 rounded h-60"
            value={generatedWorksheet}
          />
        )}
      </div>
    </div>
  );
}

export default App;
